import { i18n } from '@/i18n.js'
const ROLES = ['company_admin', 'company_region_admin', 'account_admin', 'account_manager', 'account_employee']
const accountAdminAndBelowRoles = ['account_admin', 'account_manager', 'account_employee']
const accountManagerAndBelowRoles = ['account_manager', 'account_employee']
const accountAdminAndAboveRoles = ['account_admin', 'company_region_admin', 'company_admin']
const regionAdminAndAboveRoles = ['company_region_admin', 'company_admin']


const regionAdminAndAbove = () =>
  ROLES.map(singleRoleObject).filter((role) => regionAdminAndAboveRoles.includes(role.name))

const accountAdminAndBelow = () =>
  ROLES.map(singleRoleObject).filter((role) => accountAdminAndBelowRoles.includes(role.name))


const accountManagerAndBelow = () =>
  ROLES.map(singleRoleObject).filter((role) => accountManagerAndBelowRoles.includes(role.name))


const isAccountAdminAndAbove = (role) => accountAdminAndAboveRoles.includes(role)
const isAccountEmployee = (role) => role === 'account_employee'
const isAccountManager = (role) => role === 'account_manager'
const isCompanyRegionAdmin = (role) => role === 'company_region_admin'
const isCompanyAdmin = (role) => role === 'company_admin'


function singleRoleObject(role) {
  return {
    name: role,
    translatedName: translateRole(role)
  }
}
const translateRole = (role) => i18n.t(`employees_roles.${role}`)

export {
  accountAdminAndBelow,
  isAccountEmployee,
  isAccountManager,
  isCompanyRegionAdmin,
  isCompanyAdmin,
  translateRole,
  isAccountAdminAndAbove,
  accountManagerAndBelow,
  regionAdminAndAbove
}
