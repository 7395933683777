import { index as operationsHistoryClientIndex } from '@/api_client/DailyOperationsHistory.js'

import { isString } from '@/helpers/Utils.js'

import { dateToISO8601String, ISO8601StringToDate, isValidISO8601Date, getClientDateFormatted } from '@/helpers/DateTime.js'
import { FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { ref } from 'vue'

import { i18n } from '@/i18n.js'

import { useRoute } from 'vue-router/composables'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 100

const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })

let currentApiKey = null
let accountCountryCode = null
const isLoading = ref(false)
const currentOperation = ref({})
const operationList = ref([])
const dateRange = ref([])
const pagination = ref(defaultPagination())
const operationType = ref(null)
const relatedItem = ref(null)
const accountJobFilter = ref(null)
const reportTagsFilter = ref(null)
const operationCreatedByMeFilter = ref(null)

const dateRangeDefaultValue = () => {
  const dateStart = new Date(new Date().getFullYear(), 0, 1)
  const currentDate = new Date()
  return [dateStart, currentDate]
}
export default function useOperationHistory(apiKey) {
  const operationTypes = [{ name: i18n.t('operation_history.op_type_cross_account'), id: 'cross_account' },
  { name: i18n.t('operation_history.op_type_staff'), id: 'staff' }]

  const { isUserCompanyRegionAdmin } = useAuth()
  const companyContext = () => isUserCompanyRegionAdmin.value && useRoute().meta.staffOnly === true
  const defaultOperationType = () => companyContext() ? 'cross_account' : 'account'
  const resetValues = () => {
    isLoading.value = false
    operationList.value.splice(0, operationList.value.length)
    dateRange.value = dateRangeDefaultValue()
    currentOperation.value = {}
    pagination.value = defaultPagination()
    operationType.value = defaultOperationType()
    relatedItem.value = null
    accountJobFilter.value = null
    reportTagsFilter.value = null
    operationCreatedByMeFilter.value = null
    const { accountCountryCode: accountCountryCodeRef } = useAccount()
    accountCountryCode = accountCountryCodeRef.value
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const dateRangeParams = () => {
    const isoStringArr = dateRange.value.map(date => dateToISO8601String(date))
    return isoStringArr.length === 1
      ? { dateRangeStart: isoStringArr[0], dateRangeEnd: isoStringArr[0] }
      : { dateRangeStart: isoStringArr[0], dateRangeEnd: isoStringArr[1] }
  }

  const loadOperationRequestParameters = () => {
    const { page, perPage } = pagination.value
    const operationId = currentOperation.value?.operation_id
    const result = {
      page,
      perPage,
      filters: {},
      operationType: operationType.value,
      ...dateRangeParams()
    }
    if (Number.isInteger(operationId)) {
      result.filters.operation_ids = operationId
    }
    if (Number.isInteger(relatedItem.value?.id)) {
      result.filters.related_item_id = relatedItem.value.id
    }
    if (Number.isInteger(accountJobFilter.value?.id)) {
      result.filters.job_ids = accountJobFilter.value.id
    }
    if (operationCreatedByMeFilter.value === true) {
      result.filters.created_by_me = true
    }
    if (Array.isArray(reportTagsFilter.value) && reportTagsFilter.value.length > 0) {
      result.filters.report_tags = reportTagsFilter.value.join(',')
    }
    return result
  }

  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const singleOperation = (operation) => {
    if (operationType.value === 'cross_account') {
      operation.staff_operation = true
    }
    if (operation.frequency_type === FrequencyTypes.ExectDate && isValidISO8601Date(operation.operation_next_date_raw)) {
      const fromDate = ISO8601StringToDate(operation.operation_next_date_raw)
      operation.operation_next_date = getClientDateFormatted({ countryCode: accountCountryCode, fromDate })
    }
    if (Array.isArray(operation.item_operation_list)) {
      operation.item_operation_list.forEach(item => {
        if (item.bool_value === true) {
          item.report_id = 1
        }
      })
    }
    return operation
  }

  // Methods
  const loadOperationHistory = () => {
    isLoading.value = true
    return operationsHistoryClientIndex(apiKey, loadOperationRequestParameters())
      .then(({ data: { data: operationListResponse, meta } }) => {
        const operationListVal = operationList.value
        operationListVal.splice(0, operationListVal.length)
        operationListResponse.forEach(operation => {
          operationListVal.push(singleOperation(operation))
        })
        const paginationVal = pagination.value
        paginationVal.total = meta.total
        paginationVal.page = meta.page
        paginationVal.perPage = meta.per_page
        paginationVal.totalPages = getTotalPages(paginationVal)
      })
      .finally(() => (isLoading.value = false))
  }

  const resetPageAndReload = () => {
    pagination.value.page = 1
    loadOperationHistory()
  }

  const pageChange = (page) => {
    if (pagination.value.page === page) return

    pagination.value.page = page
    loadOperationHistory()
  }

  return {
    isLoading,
    currentOperation,
    operationList,
    dateRange,
    relatedItem,
    accountJobFilter,
    reportTagsFilter,
    operationCreatedByMeFilter,
    pagination,
    operationTypes,
    operationType,
    loadOperationHistory,
    pageChange,
    resetPageAndReload
  }
}
