import Vue from 'vue'
import Router from 'vue-router'
import LandingPage from '@/components/LandingPage.vue'
import LoginPage from '@/components/login/LoginPage.vue'
import UserVerification from '@/components/login/UserVerification.vue'

import MainLayout from '@/components/layout/MainLayout.vue'
import UserNotificationsLayout from '@/components/user_notifications/UserNotificationsLayout.vue'
import Employees from '@/components/organization_structure/employee/EmployeesLayout.vue'

import TasksPageLayout from '@/components/tasks_page/TasksPageLayout.vue'

import StoresLayoutWrapper from '@/components/company_stores/StoresLayoutWrapper.vue'
import StaffAccountJobs from '@/components/company_jobs/StaffAccountJobs.vue'
import FaultyDevicesTabLayout from '@/components/account_faulty_devices/FaultyDevicesTabLayout.vue'
import AccountJobsLayout from '@/components/account_jobs/AccountJobsLayout.vue'

import ShowSingleFaultyDeviceItem from '@/components/account_faulty_devices/ShowSingleFaultyDeviceItem.vue'

import MenuLayout from '@/components/menu/MenuLayout.vue'
import UserProfile from '@/components/UserProfile.vue'
import LanguageSelectorSmallDevices from '@/components/LanguageSelectorSmallDevices.vue'
import SuppliersLayout from '@/components/organization_structure/supplier/SuppliersLayout.vue'
import CompanyProvidersLayout from '@/components/company_providers/CompanyProvidersLayout.vue'

import AccountDepartments from '@/components/account_departments/AccountDepartments.vue'

import ChatLayout from '@/components/app_chat/ChatLayout.vue'
import TaskBankPageLayout from '@/components/task_bank_page/TaskBankPageLayout.vue'

import OperationsHistoryLayout from '@/components/operations_history/OperationsHistoryLayout.vue'
import SystemGuidancePage from '@/components/SystemGuidancePage.vue'
import BlogPostPage from '@/components/blog/BlogPostPage.vue'
import AccountFaultyDeviceSettings from '@/components/account_faulty_devices/AccountFaultyDeviceSettings.vue'
import PushNotificationsHistoryPageLayout from '@/components/push_notifications_history_page/PushNotificationsHistoryPageLayout.vue'


import { scrollToTop } from '@/classes/TopScroll.js'
// const JobDailyWorksheet  = () => import(/* webpackChunkName: "JobDailyWorksheet" */ '@/components/JobDailyWorksheet.vue')
// () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)

import { objectToUrlParams } from '@/helpers/Url.js'

import { isAuthenticated } from '@/composables/useAuth.js'
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/welcome',
      name: 'welcome',
      component: LandingPage,
      meta: { requiresAuth: false }
    },
    {
      path: '/',
      name: 'login',
      component: LoginPage,
      meta: { requiresAuth: false }
    },
    {
      path: '/verify',
      name: 'verify_user',
      component: UserVerification,
      meta: { requiresAuth: false }
    },
    {
      path: '/main',
      component: MainLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/company_stores',
          name: 'company_stores',
          meta: { staffOnly: true },
          component: StoresLayoutWrapper
        },
        {
          path: '/account_faulty_devices',
          name: 'account_faulty_devices',
          component: FaultyDevicesTabLayout
        },
        {
          path: '/account_faulty_devices/:itemId/:brokenItemId',
          name: 'account_faulty_devices_show',
          component: ShowSingleFaultyDeviceItem
        },
        {
          path: '/user_notifications',
          name: 'user_notifications',
          component: UserNotificationsLayout
        },
        {
          path: '/staff/user_notifications',
          name: 'staff/user_notifications',
          meta: { staffOnly: true },
          component: UserNotificationsLayout
        },
        {
          path: '/staff/company_faulty_devices/:apiKey/:itemId/:brokenItemId',
          name: 'staff/company_faulty_devices_show',
          meta: { staffOnly: true },
          component: ShowSingleFaultyDeviceItem
        },
        {
          path: '/staff/company_faulty_devices',
          name: 'staff/company_faulty_devices',
          meta: { staffOnly: true },
          component: FaultyDevicesTabLayout
        },
        {
          path: 'work_panel/employees',
          name: 'work_panel/employees',
          component: Employees
        },
        {
          path: '/staff/work_panel/employees',
          name: 'staff/work_panel/employees',
          meta: { staffOnly: true },
          component: Employees
        },
        {
          path: 'menu',
          name: 'menu',
          component: MenuLayout
        },
        {
          path: '/staff/menu',
          name: 'staff/menu',
          meta: { staffOnly: true },
          component: MenuLayout
        },
        {
          path: 'user_profile',
          name: 'user_profile',
          component: UserProfile
        },
        {
          path: 'staff/user_profile',
          name: 'staff/user_profile',
          meta: { staffOnly: true },
          component: UserProfile
        },
        {
          path: 'language_selection',
          name: 'language_selection',
          component: LanguageSelectorSmallDevices
        },
        {
          path: 'staff/language_selection',
          name: 'staff/language_selection',
          meta: { staffOnly: true },
          component: LanguageSelectorSmallDevices
        },
        {
          path: 'suppliers',
          name: 'suppliers',
          component: SuppliersLayout
        },
        {
          path: 'staff_account/jobs',
          name: 'staff_account/jobs',
          meta: { staffOnly: true },
          component: StaffAccountJobs
        },
        {
          path: 'jobs',
          name: 'jobs',
          component: AccountJobsLayout
        },
        {
          path: 'account_departments',
          name: 'account_departments',
          component: AccountDepartments
        },
        {
          path: 'chat',
          name: 'chat',
          component: ChatLayout
        },
        {
          path: 'staff/chat',
          name: 'staff/chat',
          meta: { staffOnly: true },
          component: ChatLayout
        },
        {
          path: 'staff/task_history',
          name: 'staff/task_history',
          meta: { staffOnly: true },
          component: OperationsHistoryLayout
        },
        {
          path: 'task_history',
          name: 'task_history',
          component: OperationsHistoryLayout
        },
        {
          path: 'help',
          name: 'help',
          component: SystemGuidancePage
        },
        {
          path: 'staff/help',
          name: 'staff/help',
          meta: { staffOnly: true },
          component: SystemGuidancePage
        },
        {
          path: 'tickets_settings',
          name: 'tickets_settings',
          component: AccountFaultyDeviceSettings
        },
        {
          path: 'blog',
          name: 'blog',
          component: BlogPostPage
        },
        {
          path: 'staff/blog',
          name: 'staff/blog',
          meta: { staffOnly: true },
          component: BlogPostPage
        },
        {
          path: 'tasks',
          name: 'tasks',
          component: TasksPageLayout
        },
        {
          path: 'company_service_providers',
          name: 'company_service_providers',
          component: CompanyProvidersLayout
        },
        {
          path: 'task_bank',
          name: 'task_bank',
          component: TaskBankPageLayout
        },
        {
          path: 'push_history',
          name: 'push_history',
          component: PushNotificationsHistoryPageLayout
        }

      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const notRequiresAuth = !to.matched.some(record => record.meta.requiresAuth)
  if (notRequiresAuth) {
    return next()
  }
  if (isAuthenticated.value) {
    return next()
  }

  let redirect = to.path
  const query = objectToUrlParams(to.query)
  if (query.length > 0) redirect += `?${query}`

  next({ name: 'login', query: { redirect } })
})

router.afterEach((to, from) => {
  scrollToTop()
})

export default router
